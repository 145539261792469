import {createTheme, responsiveFontSizes} from "@mui/material";

export const peTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: ['DM Sans', 'sans-serif'].join(','),
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#00FC9D",
            contrastText:"#2F2A2D"
        },
        background:{
            default: "#582BAE",
        }
    }
}))
