import React, {useEffect} from "react";
import formMap from "../assets/data/pe-form-map.json";
import {useDataStore} from "../state/state";
import {Box, Button, CircularProgress, Grid, Snackbar, Stack, Typography} from "@mui/material";
import {FormField} from "./FormField";
import axios from "axios";
import {API_URL} from "../config";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const RegistrationForm = () => {
    const {initialize, update, data} = useDataStore()
    const [sending, setSending] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [message, setMessage] = React.useState('')

    useEffect(() => {
        initializeData()
    }, [])
    const initializeData = async () => {
        let d = {}
        formMap.fields.filter(f => f.type !== 'note').forEach(f => {
                let key = f.id
                if (f.type === 'date' || f.type === 'datetime' || f.type === 'time') {
                    d[key] = null
                } else if (f.type === 'checkbox') {
                    d[key] = false
                } else {
                    d[key] = ''
                }
            }
        )
        initialize(d)
    }

    const sendData = async (e) => {
        e.preventDefault()
        setSending(true)
        await axios.post(`${API_URL}/register`, data)
            .then(r => {setSuccess(true)})
            .catch((e) => {
                setError(true)
                setMessage(e.response.data.error)
            })
        setSending(false)
    }

    return (
        <form onSubmit={sendData}>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(!error)}>
                <Alert onClose={() => setError(!error)} severity="error" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Grid container spacing={2} sx={{py: 2}}>
                {Object.keys(data).length > 0 && formMap.fields.map(f =>
                    <FormField field={f} key={f.id}/>
                )}
            </Grid>
            <Box sx={{textAlign: 'center', pt: 3}}>
                {success ?
                    <Typography color={'#E7EC20'} variant={'h6'} fontWeight={'bold'} fontFamily={'Corben'}>
                        Grazie per esserti registrato!
                    </Typography>
                    :
                    sending ?
                        <CircularProgress/>
                        :
                        <Button type={'submit'} variant={'contained'} sx={{fontWeight: 'bold'}}>
                            Registrati
                        </Button>
                }
            </Box>
        </form>
    )
}
