import logo from './logo.svg';
import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";

function App() {
    return (
        <div>
            <Routes>
                <Route path="/" element={<Navigate to={'/public-engagement'}/>}/>
                <Route path="/public-engagement" element={<Home/>}/>
            </Routes>
        </div>
    );
}

export default App;
