import React from "react";
import {
    Box,
    Checkbox,
    FormControl, FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField, Typography,
    RadioGroup,
    Radio
} from "@mui/material";
import {useDataStore} from "../state/state";

export const FormField = ({field}) => {
    const {data, update} = useDataStore()

    return (
        <Grid item xs={field.cols?.xs ?? 12} md={field.cols?.md ?? 6}>
            {(() =>{
                switch (field.type){
                    case 'select':
                        return <FormControl required variant="standard" fullWidth>
                            {field.label && <InputLabel id={field.id}>{field.label}</InputLabel>}
                            <Select
                                label={field.label}
                                value={data[field.id]}
                                onChange={(e) => update(field.id, e.target.value)}
                            >
                                {field.values.map(v =>
                                    <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    default:
                        return <TextField required={field.required}  onChange={(e) => update(field.id, e.target.value)} type={field.type} value={data[field.id]} label={field.label} variant={"standard"} sx={{width:'100%'}}/>
                }
            })()}
        </Grid>
    )
}
