import {create} from "zustand";
import {produce} from 'immer'

export const useDataStore = create((set, get) => ({
    data:{},
    update: (id, value) => {
        set(
            produce((draft) => {
                const d = draft.data
                d[id] = value
            })
        )
    },
    initialize: (startData) => set( () => ({data: startData}))
}))
