import React from "react";
import {Box, Container, Typography} from "@mui/material";
import {RegistrationForm} from "../components/RegistrationForm";

export const Home = () => {
    return (
        <Box>
            <Box sx={{width:'min(60rem, 100%)', margin:'auto'}}>
                <img style={{width: '100%'}} src={require('../assets/images/header.jpg')}/>
            </Box>
            <Container maxWidth={'md'} sx={{py:2}}>
                <Typography textAlign={'center'} variant={'h5'} fontWeight={'bold'} fontFamily={'Corben'}>
                    <span style={{color:'#E7EC20'}}>Iscriviti</span> all'evento compilando il form qui sotto.
                </Typography>
                <RegistrationForm/>
            </Container>
        </Box>
    )
}
